
<script>
import {genListOfYears, listForCorrect} from '@/modules/budget/budgetCorrectHelper';

export default {
    name: 'CorrectMixin',
    methods: {
        getRegion(regCode) {
            const res = this.listReg.find(value => value.code == regCode);
            if (res) {
                return res;
            } else {
                return {
                    name_ru: "Не выбран"
                };
            }
        },
        getVariantUidName(variant_uid) {
            const vr = this.listOfVariants.find(value => value.variant_uuid == variant_uid);
            return this.getVariantName(vr)
        },
    },


    computed: {
        listOfVariants() {
            return listForCorrect(this.listVariants, this.repYear);
        },
        listOfYears() {
            return genListOfYears();
        },
        is_variant_editable() {
            let result = true;
            const vrnts = this.listOfVariants;
            let sel_variant = null;
            for (const vrnt of vrnts) {
                if (vrnt.variant_uuid == this.variant) {
                    sel_variant = vrnt;
                    break
                }
            }
            if (this.variant == '') {
                result = false;
            }
            if (sel_variant != null) {
                if ((!sel_variant.attribute) || (sel_variant.state)) {
                    result = false
                }
            }
            return result
        }
    },
}
</script>

<style scoped>

</style>